
          @import "./src/assets/styles/scss/common/_variables.scss";
          @import "./src/assets/styles/scss/common/_mixins.scss";
        

.app {
  height: 100vh;
  width: 100vw;
  background-color: $clr-black-1;

  &__content {
    position: absolute;
    z-index: 99;
    width: 100vw;
    overflow-y: scroll;
    overflow-x: hidden;
  }

  &_v {
    position: fixed;
    bottom: 10px;
    left: 10px;
    color: $clr-white-3;
  }
}
