
          @import "./src/assets/styles/scss/common/_variables.scss";
          @import "./src/assets/styles/scss/common/_mixins.scss";
        

body {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
}
