
          @import "./src/assets/styles/scss/common/_variables.scss";
          @import "./src/assets/styles/scss/common/_mixins.scss";
        

.header {
  background-color: $clr-black-1;
}

.logo {
  color: $clr-white-4;
  fill: $clr-white-4;
  padding-right: 32px;

  border-right: 1px solid $clr-black-3;
}


.npm {
  * {
    color: $clr-white-1 !important;
    fill: $clr-red-1 !important;
  }
}

