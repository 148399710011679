$clr-black-1: #100F13;
$clr-black-2: #4B4951;
$clr-black-3: #4B4951;
$clr-black-4: #050506CC;

$clr-white-1: #E0E0E0;
$clr-white-2: rgba($clr-white-1, 0.1);
$clr-white-3: rgba($clr-white-1, 0.5);
$clr-white-4: #FFF;

$clr-yellow-1: #FEFD3E;
$clr-yellow-2: rgba($clr-yellow-1, 0.5);

$clr-red-1: #FF4747;
$clr-red-2: rgba($clr-red-1, 0.4);

$clr-green-1: #75F23A;

$colors-pallet: (
        black-1: $clr-black-1,
        black-2: $clr-black-2,
        black-3: $clr-black-3,
        black-4: $clr-black-4,

        white-1: $clr-white-1,
        white-2: $clr-white-2,
        white-3: $clr-white-3,

        yellow-1: $clr-yellow-1,

        red-1: $clr-red-1,
        red-2: $clr-red-2,

        green-1: $clr-green-1,
);

$border: 1px solid $clr-black-3;
